import { config } from "../config";
import { hasData } from "./hasData";

export function getAvatarUrl(
         avatar_url
        ,avatar_url_sfw
        ,bol_nsfw
        ,avatar_crdate
        ,override_url = ''
        ,user_id
    ) {


    const avatar_url_alt = hasData(avatar_url_sfw) 
                            ? avatar_url_sfw 
                            : hasData(override_url)
                               ? override_url
                               : config.avatar.url_missing

    if (!hasData(avatar_url) || bol_nsfw == 1) return avatar_url_alt
    return `${avatar_url}?c=${avatar_crdate}`
    

}