import React, {useEffect, useState} from 'react'
import SvgJsx from '@/components/templateux/svg/svg-jsx'
import LoaderToggle from '@/components/templateux/loading/loader-toggle'
import { useSession } from 'next-auth/client'
import { confirmSignin } from '@/lib/utils/confirmSignin'
import { hasData } from '@/lib/utils/hasData'
import toast from 'react-simple-toasts';
import { toastConfig } from 'react-simple-toasts';
import Image from 'next/image'

export default function FilepickerImage({
   file
  ,set_file
  ,buttontext="Upload an image"
  ,buttontextshort="Upload an image"
  ,form_id
  ,media_type='avatar'
  ,max_size
  ,set_upload_complete
  ,file_class='rounded-full'
  ,container_class=''
  ,success_message=`Your file finished uploading. Please save your changes.`
  ,failure_message=`Upload failed. If this keeps happening, please try a smaller file - ${(max_size)} or less - in PNG, GIF or JPG format.`
  ,submitting
  ,set_submitting
}) {


  const [ session, loading ] = useSession()
  const [uploading,set_uploading] = useState(false)
  const [thumb,set_thumb] = useState(file)

  useEffect(() => {
    if (hasData(file)) {
      set_thumb(file);
    } else {
      set_thumb('') //config.avatar.url_missing
    }
  }, [file]);

    const uploadPhoto = async (e) => {

      if (!session) {
        confirmSignin(`/`,`Uploading`)
        return false;
      }

      if (!hasData(e.target.files[0]?.name)) {
        return false;
      }
      
      set_uploading(true)

      const file = e.target.files[0];
      const filename = encodeURI(file?.name);  //encode the file name to send it to the API safely


      //INSERT & GET PRESIGNED URL FROM AWS SDK
      const res = await fetch(`/api/private/media/upload-image?file=${filename}&form_id=${form_id}&type=${media_type}`); //API decodes the filename internally

      const results = await res.json();
      
     // console.log("results",results)

      const { url, fields } = results.post; 
      const { media_id, media_name, media_extension} = results.media;

      const formData = new FormData();
      const media_key=fields.key
      const media_url= `${url}/${encodeURI(media_key)}`  //reencode the file name for posting to S3

     // console.log("media_url",media_url)

      Object.entries({ ...fields, file }).forEach(([key, value]) => {
          //@ts-ignore
        formData.append(key, value);
      });

      //UPLOAD THE FILE WITH PRESIGNED URL
      const upload = await fetch(url, {
        method: 'POST',
        body: formData,
      });

    if (upload.ok) {

      //UPDATE THE DATABASE WITH NEW URL
      const response = await fetch(`/api/private/media/update-upload`, {
        method: 'POST',
        body: JSON.stringify({ 
           media_id: media_id
          ,media_url:  media_url
          ,media_key:  media_key
          ,media_name: media_name
          ,media_extension: media_extension
        }),
      });

      set_thumb(media_url);
      set_file(media_url);
      set_uploading(false);
      set_upload_complete(true);
      
      toastConfig({
        time: 5000,
        className: '',
        position: 'right'
      });
      toast(success_message, { time: 3000, className: '', clickable: true, clickClosable: false });


    } else {
      alert(failure_message);
    }
  };

  return (
      <>
        
        <label 
          htmlFor={`${form_id}`} 
          className=""
        >
          <div className={container_class}>
          
            {thumb &&
              <div  className={`${file_class} shadow-lg border-5  cursor-pointer gap-2 border inline-block
              border-gray-600 hover:border-gray-200 relative`}>
                <Image
                  src={`${thumb}`}
                
                  alt="Image"
                  layout={"fill"}
                  quality={100}
                  className={file_class}
                  placeholder="empty"
                  //blurDataURL={config.avatar.blurdataurl}
                /> 
              </div>
            }
            <div
              className=" flex border cursor-pointer whitespace-nowrap px-2 py-1 my-auto rounded-full shadow ml-2 
              bg-gray-800 border-black  text-gray-300 hover:text-gray-100 hover:border-gray-200 
              ">
              <div className="flex flex-0 items-center content-center">
                {!uploading &&
                <SvgJsx 
                  type='outline' 
                  icon='arrow-circle-up' 
                  className='w-10 h-10 flex-0' 
                /> 
                }
                <div className="flex-1 my-auto text-lg mx-2 w-full">
                  
                  <LoaderToggle
                    loadingstate={uploading}
                    actionText={buttontext}
                    actionTextShort={buttontextshort}
                    loadingClass='text-gray-400 w-7 h-7'
                  />
                </div>
              </div>
            </div>
          </div>
                  
          <input
            id={`${form_id}`}
            onChange={uploadPhoto}
            type="file"
            accept="image/png, image/jpeg, image/gif, image/svg+xml"
            className=""
            hidden
            disabled={submitting || uploading}
          />
        </label>
        
        
      </>
    );
  }