import LoadingRotary from "./loading-rotary";

export default function LoaderToggle({
     loadingText='Dialing...'
    ,actionText='Save'
    ,actionTextShort='Save'
    ,loadingstate
    ,loadingClass=''
    ,actionClass=''
    ,toggle={
      bgColor: 'transparent'
      ,centerColor: '#111827'
      ,holeColor: '#111827'
      ,pointerColor: '#ff0000'
      ,dialerColor: '#ffffff'
    }
}) {

  //console.log("loadingstate",loadingstate)

    return(<>
    <div className="flex items-center content-center">
      {!loadingstate && 
      <div className={`${actionClass} flex-0`}>
        <span className="hidden sm:block">
          {actionText}
        </span>
        <span className="sm:hidden block">
          {actionTextShort}
        </span>
      </div>
      }

      {loadingstate &&
      <div className='flex-0 flex items-center content-center'>

        <div className='flex-0'>
        
          <LoadingRotary     
              bgColor={toggle.bgColor}
              holeColor={toggle.holeColor}
              centerColor={toggle.centerColor}
              pointerColor={toggle.pointerColor}
              dialerColor={toggle.dialerColor}
              classSize='h-7 w-7 block -ml-2'
              classAnimate='animate-spin'
          />

        </div>

        {loadingText !== '' &&
        <div className="ml-2 whitespace-nowrap flex-0">
          {loadingText}
        </div> 
        }

      </div>
      }

    </div>
    
        
  </>)
  
  }